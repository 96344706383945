import { role } from "./category"

export const adminMenu = {
    menu: [
        {
            id:0,
            title: "Dashboard",
            url: "",
            icon: "FaHome",
            roles: [role.admin.id, role.manager.id, role.student.id]
        },
        {
            id:2,
            title: "Students",
            url: "students",
            icon: "FaUsers",
            roles: [role.admin.id, role.manager.id]
        },
        {
            id:1,
            title: "Workshop",
            url: "workshops",
            icon: "FaUsersCog",
            roles: [role.admin.id, role.manager.id, role.student.id]
        },
        {
            id:3,
            title: "Reports",
            url: "reports",
            icon: "FaReceipt",
            roles: [role.admin.id, role.manager.id]
        },
        {
            id:4,
            title: "Configuration",
            shortTitle: "Config",
            url: "config",
            icon: "FaCog",
            roles: [role.admin.id, role.manager.id]
        }
    ]
}