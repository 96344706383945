import { FontAwesome } from './common/FontAwesome';
import {adminMenu} from '../constants/menu';
import { useContext, useEffect, useState } from 'react';
import { cloneDeep, get, set } from 'lodash';
import { useNavigate } from 'react-router';
import { Popover } from 'react-tiny-popover';
import { AppContext } from '../App';
import Button from './controls/Button';
import { Sidebar } from './navigation/Sidebar';
import { BottomNavbar } from './navigation/BottomNavbar';

export const Layout = ({children}) =>{
    const {user, student, removeUser} = useContext(AppContext);
    const [menuState, setMenuState] = useState({menu:[]});
    const [clickId, setClickId] = useState(0);
    const [title, setTitle] = useState("Dashboard");
    const [breadcrumbs, setBreadcrumbs] = useState("Dashboard");
    const [collapse, setCollapse] = useState(false);
    const [userMenu, setUserMenu] = useState(false);
    const navigate = useNavigate();

    const selectPath = (obj, path, titlePath, url) => {
        if(obj.submenu){
            const expandPath = `${path}.expand`;
            const menuTemp = cloneDeep(menuState);
            const isExpanded = get(menuTemp, expandPath);
            set(menuTemp, expandPath, !isExpanded);
            setMenuState(menuTemp);
        }else{
            setClickId(obj.id);
            setTitle(obj.title);
            setBreadcrumbs(titlePath);
            if(url){
                navigate(url);
            }
        }
    }

    useEffect(()=>{
        const menuTemp = {
            menu: adminMenu.menu.filter(m => m.roles.includes(user.role.id))
        }
        const paths = window.location.pathname.split("/").filter(p => p.length > 0);
        const indexes = [];
        let currentMenu = menuTemp.menu;
        let lastMenu;
        let titlePath = "";
        let menuPath = "";
        paths.forEach((path, index) => {
            let findIndex = null, submenu = null;
            currentMenu.find((m, mi)=>{
                if(m.url === path){
                    findIndex = mi;
                    submenu = m.submenu;
                    indexes.push(mi);
                    titlePath += (index == 0 ? m.title : ` / ${m.title}`);
                    lastMenu = m;
                    return m;
                }
            });
            if(findIndex != null && submenu != null){
                currentMenu = submenu;
            }
        });
        indexes.forEach((val, count)  => {
            if(count==0){
                menuPath += `menu[${val}]`;
            }else{
                menuPath += `.submenu[${val}]`;
            }
            set(menuTemp, `${menuPath}.expand`, true);
        });
        setMenuState(menuTemp);
        if(lastMenu){
            setClickId(lastMenu.id);
            setTitle(lastMenu.title);
            setBreadcrumbs(titlePath);
        }
    },[]);

    const getParentMenu = () =>{
        if(!breadcrumbs){
            return "";
        }
        return breadcrumbs.split("/")[0].trim();
    }

    const handleLogOut = () => {
        removeUser();
        setTimeout(()=>{
            navigate("/");
        }, 500);
    }

    return (
        <div className="flex h-screen">
            <div className={`relative h-screen overflow-hidden filter drop-shadow-2xl hidden lg:block ${collapse? "pr-4 w-16" : "p-4 w-64"}`}>
                <Sidebar 
                    onSelect={selectPath} selectedId={clickId} menu={menuState.menu}
                />
            </div>
            <div className='overflow-auto fixed inset-x-0 bottom-0 lg:hidden z-20 bg-brand-100'>
                <BottomNavbar 
                    onSelect={selectPath} selectedId={clickId} menu={menuState.menu}
                />
            </div>
            <div className="flex flex-col flex-grow h-full text-dark-100 w-3/4">
                <div className="flex py-3 px-5">
                    <div className="text-lg font-bold flex-grow">
                        {title}
                    </div>
                    <div className="rounded-full w-8 h-8 hover:bg-slate-100 cursor-pointer flex flex-col ml-2"
                        onClick={()=>setUserMenu(true)}
                    >
                        <FontAwesome className="w-5 m-auto" name="FaUser"/>
                    </div>
                    <Popover
                        isOpen={userMenu}
                        positions={['bottom']} // preferred positions by priority
                        reposition={true}
                        containerClassName="z-20"
                        content={
                            <div className="p-3 bg-white border rounded-lg w-72 text-sm mr-5">
                                <div className="flex">
                                    <div className='w-20'>
                                        Username
                                    </div>
                                    <div className='flex-grow'>
                                        : {user.username}
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className='w-20'>
                                        Role
                                    </div>
                                    <div className='flex-grow'>
                                        : {user.role.name}
                                    </div>
                                </div>
                                {
                                    !student? null:
                                    <div className="flex">
                                        <div className='w-20'>
                                            Name
                                        </div>
                                        <div className='flex-grow'>
                                            : {student.attributes.fullName}
                                        </div>
                                    </div>
                                }
                                <Button icon="FaPowerOff" title="Log Out" onClick={handleLogOut} customClass="bg-red-500 hover:bg-red-600 text-white w-fit mt-5"/>
                            </div>
                        }
                        onClickOutside={() => setUserMenu(false)}
                    >
                        <div />
                    </Popover>
                </div>
                <div className="flex-grow overflow-hidden z-10">
                    {children}
                </div>
            </div>
        </div>
    )
}
