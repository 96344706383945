export const DotCircleSpinner = () => {
    return (
        <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export const DotSerialSpinner = () => {
    return(
        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    )
}