
import { Http} from "@capacitor-community/http";
import { API_HOST } from "../constants/env";
export const RESPONSE_OK = 200;
export const RESPONSE_NOK = 500;
export const IS_DEMO = true;

let token = "";
export const setToken = (newToken) => {
    token = newToken;
}

export const register = async ({data}) => {
    try{
        let resp = await Http.post({
            url: `${API_HOST}/auth/local/register`,
            headers:{ 'Content-Type': 'application/json' },
            data: data
        });
        let respData = resp.data;
        if(!respData){
            return {
                data: null,
                error: "No Response"
            }
        }
        if(respData.error){
            return {
                data: null,
                error: `Error ${respData.error.status} | ${respData.error.name}: ${respData.error.message}`
            }
        }
        return {
            error: null,
            data: {...resp.data, jwt:token}
        }
    }catch(err){
        return {
            data: null,
            error: JSON.stringify(err)
        }
    }
} 

export const login = async (username, password) => {
    try{
        let resp = await Http.post({
            url: `${API_HOST}/auth/local`,
            headers:{ 'Content-Type': 'application/json' },
            data: {
                identifier: username,
                password: password
            }
        });
        let respData = resp.data;
        if(!respData){
            return {
                data: null,
                error: "No Response"
            }
        }
        if(respData.error){
            return {
                data: null,
                error: `Error ${respData.error.status} | ${respData.error.name}: ${respData.error.message}`
            }
        }
        token = respData.jwt;
        resp = await Http.get({
            url: `${API_HOST}/users/me?populate=*`,
            headers:{ 'Content-Type': 'application/json' , 'Authorization' : `Bearer ${token}`},
        });
        if(!resp){
            return {
                data: null,
                error: "No Response"
            }
        }
        if(!resp.data || !resp.data.username){
            return {
                data: null,
                error: "Username/password salah"
            }
        }
        return {
            error: null,
            data: {...resp.data, jwt:token}
        }
    }catch(err){
        return {
            data: null,
            error: JSON.stringify(err)
        }
    }
}

export const getTodayDateTimeBy = async(user) => {
    try{
        const resp = await Http.put({
            url: `${API_HOST}/latest-access`,
            headers:{ 
                'Content-Type': 'application/json' , 
                'Authorization' : `Bearer ${token}`
            },
            data:{
                data:{
                    username: user
                }
            }
        });
        const respData = resp.data;
        if(!respData.data){
            return {
                data: null,
                error: "Response data not found"
            }
        }
        if(!respData.data.attributes){
            return {
                data: null,
                error: "Response data attibutes not found"
            }
        }
        return {
            data: respData.data.attributes.updatedAt,
            error: null
        }
    }catch(err){
        return {
            data: null,
            error: JSON.stringify(err)
        }
    }
}

export const httpPost = async (url, data) => {
    try{
        const resp = await Http.post({
            url,
            headers:{ 'Content-Type': 'application/json' , 'Authorization' : `Bearer ${token}`},
            data: {...data}
        });
        return {
            error: null,
            data: resp.data.data
        }
    }catch(err){
        return {
            data: null,
            error: JSON.stringify(err)
        }
    }
}

export const httpPut = async (url, data) => {
    try{
        const resp = await Http.put({
            url,
            headers:{ 'Content-Type': 'application/json' , 'Authorization' : `Bearer ${token}`},
            data: {...data}
        });
        return {
            error: null,
            data: resp.data.data
        }
    }catch(err){
        return {
            data: null,
            error: JSON.stringify(err)
        }
    }
}


export const httpGet = async (url) => {
    try{
        const resp = await Http.get({
            url,
            headers:{ 'Content-Type': 'application/json' , 'Authorization' : `Bearer ${token}`},
        });
        const respData = resp.data;
        if(!respData){
            return {
                data: null,
                error: "No Response"
            }
        }
        if(respData.error){
            return {
                data: null,
                error: `Error ${respData.error.status} | ${respData.error.name}: ${respData.error.message}`
            }
        }
        return {
            error: null,
            data: respData.data
        }
    }catch(err){
        return {
            data: null,
            error: JSON.stringify(err)
        }
    }
}

export const httpDelete = async (url) => {
    try{
        const resp = await Http.del({
            url,
            headers:{ 'Content-Type': 'application/json' , 'Authorization' : `Bearer ${token}`},
        });
        return {
            error: null,
            data: "Delete Success"
        }
    }catch(err){
        return {
            data: null,
            error: JSON.stringify(err)
        }
    }
}