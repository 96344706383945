import { httpGet, httpPost, httpPut } from "./Global";
import { API_HOST } from "../constants/env";

export const getStudents = async ({klass, year, dept}) => {
    const response = await httpGet(`${API_HOST}/students?populate=*&filters[class][$eq]=${klass}&filters[year][$eq]=${year}&filters[dept][$eq]=${dept}&pagination[pageSize]=50`);
    return response;
}

export const getStudentByUserId = async ({userId}) => {
    const response = await httpGet(`${API_HOST}/students?filters[user][id][$eq]=${userId}`);
    return response;
}

export const postStudent = async ({data}) => {
    const response = await httpPost(`${API_HOST}/students`, {data});
    return response;
}

export const putStudent = async ({id, data}) => {
    data.photo = null;
    const response = await httpPut(`${API_HOST}/students/${id}`, {data});
    return response;
}

// const initialValues = {
//     name:"",
//     department:"",
//     semester:null,
//     departmentshort:"",
//     minWorkHour:0
// };
