export const approvalStatus = {
    approved: "APPROVED",
    rejected: "REJECTED",
    waiting: "WAITING FOR APPROVAL"
}

export const role = {
    student: {
        id: 1,
        name:"STUDENT"
    },
    manager: {
        id: 2,
        name:"MANAGER"
    },
    admin: {
        id: 3,
        name:"ADMIN"
    }
}

export const studentClass = [
    {
        label: "A",
        value: "A"
    },
    {
        label: "B",
        value: "B"
    }
];

export const studentDept = [
    {
        label: "D3 Teknik Listrik Industri",
        value: "TLI-D3"
    },
    {
        label: "D2JC Teknik Listrik Industri",
        value: "TLI-D2JC"
    },
    {
        label: "D3 Teknik Mesin Alat Berat",
        value: "TMAB-D3"
    },
    {
        label: "D3 Teknik Bangunan Kapal",
        value: "TBK-D3"
    },
];

export const studentYear = [
    {
        label: "2018",
        value: 2018
    },
    {
        label: "2019",
        value: 2019
    },
    {
        label: "2020",
        value: 2020
    },
    {
        label: "2021",
        value: 2021
    },
    {
        label: "2022",
        value: 2022
    },
    {
        label: "2023",
        value: 2023
    },
];
//DEV
// export const LOCAL_STORAGE_KEY = "logbookUser";
//PROD
export const LOCAL_STORAGE_KEY = "simwoUser";