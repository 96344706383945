import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import { createContext, useEffect, useState, Suspense, lazy} from 'react';
import {Toast} from "./components/common/Toast";
import { addDays } from "date-fns";
import { Layout } from "./components/Layout";
import { setToken } from "./apis/Global";
import {LOCAL_STORAGE_KEY, role} from "./constants/category";
import { getStudentByUserId } from "./apis/Student";
import "flatpickr/dist/themes/material_blue.css";
import { DotCircleSpinner } from "./components/common/Spinner";
import NotFound from './pages/NotFound';
const Home = lazy(() => import('./pages/Home'));
const Workshops = lazy(() => import('./pages/Workshops'));
const Students = lazy(() => import('./pages/Students'));
const Reports = lazy(() => import('./pages/Reports'));
const Login = lazy(() => import('./pages/Login'));
const Configuration = lazy(() => import('./pages/Configuration'));

export const AppContext = createContext();

function App() {
  const [user, setUser] = useState(null);
  const [student, setStudent] = useState(null);
  const [workshop, setWorkshop] = useState(null);
  const [studentWorkshop, setStudentWorkshop] = useState(null);
  
  const initAppData = async () => {
    if(!user){
      try {
        // Get from local storage by key
        const _user = window.localStorage.getItem(LOCAL_STORAGE_KEY);
        if(!_user){
          setUser(null);
          return;
        }
        const _userJson = JSON.parse(_user);
        if(!_userJson || !_userJson.expired){
          setUser(null);
          return;
        }
        const currentDate = new Date();
        const expiryDate = new Date(_userJson.expired);
        if(expiryDate < currentDate){
          setUser(null);
          return;
        }
        await setUserWithExpiryDate(_userJson); //set expiration login state
      } catch (error) {
        console.log(error);
        setUser(null);
      }
    }else{
      try {
        // Save to local storage
        window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(user));
      } catch (error) {
        console.log(error);
      }
    }
  }
  
  useEffect(()=>{
    initAppData();
  },[JSON.stringify(user)]);

  const setUserWithExpiryDate = async(_user) => {
    if(!_user){
      return;
    }
    const currentDate = new Date();
    setToken(_user.jwt);
    setUser({
      ..._user,
      expired : addDays(currentDate, 1) //set expiration login state
    });
    if(_user.role && (_user.role.id === role.student.id)){
        const resp = await getStudentByUserId({userId: _user.id});
        if(resp.error || !resp.data[0]){
            window.pushToast("Student data not found", "error");
            removeUser();
            return;
        }
        setStudent(resp.data[0]);
    }else{
        setStudent(null);
    }
  }

  const removeUser = () => {
    try {
      // Save to local storage
      window.localStorage.removeItem(LOCAL_STORAGE_KEY);
      setUser(null);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <AppContext.Provider value={{
      user,
      setUser,
      setUserWithExpiryDate,
      removeUser,
      student,
      setStudent,
      workshop,
      setWorkshop,
      studentWorkshop,
      setStudentWorkshop
    }}>
      <BrowserRouter>
        {
          !user?
          <Routes>
            <Route path={"/"}
              element={
                <Suspense fallback={<div className="w-fit mx-auto"><DotCircleSpinner/></div>}>
                  <Login/>
                </Suspense>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>:
          <Layout>
            <Routes>
              <Route exact path={"/"} 
                element={
                  <Suspense fallback={<div className="w-fit mx-auto"><DotCircleSpinner/></div>}>
                    <Home/>
                  </Suspense>
                }
              />
              <Route exact path={"/workshops"} 
                element={
                  <Suspense fallback={<div className="w-fit mx-auto"><DotCircleSpinner/></div>}>
                    <Workshops/>
                  </Suspense>
                }
              />
              {
                user.role.id === role.student.id ? null :
                <Route exact path={"/students"} 
                  element={
                    <Suspense fallback={<div className="w-fit mx-auto"><DotCircleSpinner/></div>}>
                      <Students/>
                    </Suspense>
                  }
                />
              }
              {
                user.role.id === role.student.id ? null :
                <Route exact path={"/reports"} 
                  element={
                    <Suspense fallback={<div className="w-fit mx-auto"><DotCircleSpinner/></div>}>
                      <Reports/>
                    </Suspense>
                  }
                />
              }
              <Route exact path={"/config"} 
                element={
                  <Suspense fallback={<div className="w-fit mx-auto"><DotCircleSpinner/></div>}>
                    <Configuration/>
                  </Suspense>
                }
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Layout>
        }
      </BrowserRouter>
      <Toast/>
    </AppContext.Provider>
  );
}

export default App;
