import { FontAwesome } from "../common/FontAwesome";
import { DotSerialSpinner } from "../common/Spinner";


export default function Button({onClick, title, icon, loading, customClass, type, fitContent, disabled}){
    return(
        <button type={type? type : ""} className={`flex cursor-pointer py-1 px-3 rounded-lg items-center ${fitContent? "w-fit" : "w-full"} ${customClass? customClass : "bg-brand-20 hover:bg-brand-50 text-white"}`}
            onClick={onClick} disabled={disabled}
        >
            {
                loading? 
                <span className="w-8">
                    <DotSerialSpinner/>
                </span> :
                !icon? null:
                <span className="w-8">
                    <FontAwesome name={icon} className="w-4 mr-3"/>
                </span>
            } 
            <span className="flex-grow text-center">{title}</span>
        </button>
    )
}